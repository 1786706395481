import styles from "./styles.module.css";
import { ICheckboxProps } from "./types";

export default function Checkbox({
  orientation = "row",
  error,
  ...rest
}: Readonly<ICheckboxProps>) {
  const hasError = Boolean(error);

  return (
    <>
      <div
        style={{ flexDirection: orientation }}
        className={`${styles["container"]} ${
          !rest.label ? styles["border"] : ""
        }`}
      >
        <input
          type="checkbox"
          {...rest}
          onChange={(event) => {
            if (rest.onChange) {
              rest.onChange(event);
            }
          }}
        />
        {rest.label && <label htmlFor={rest.name}>{rest.label}</label>}
      </div>
      {hasError && <p className={styles["text-error"]}>{error}</p>}
    </>
  );
}
