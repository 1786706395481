import { useState } from "react";
import Image from "next/image";
import InputMask from "react-input-mask";
import { BaiJamjureeFont } from "@/styles/typography";
import styles from "./styles.module.css";
import { IInputProps } from "./types";

export default function Input({
  flex,
  mask,
  width,
  icon,
  iconTopOffset = "1.65rem",
  error,
  ...rest
}: Readonly<IInputProps>) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const hasError = Boolean(error);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div style={{ flex, width }} className={styles["container"]}>
      {rest.label && <label htmlFor={rest.name}>{rest.label}</label>}

      {icon && (
        <Image
          style={{ top: iconTopOffset }}
          className={styles["icon"]}
          src={icon}
          width={22}
          height={22}
          alt={rest.alt || "Input icon"}
        />
      )}

      {/* @ts-ignore */}
      <InputMask
        style={{ fontFamily: BaiJamjureeFont.style.fontFamily, width }}
        className={`${hasError ? "error" : ""} ${
          icon ? styles["with-icon"] : ""
        }`}
        mask={mask || ""}
        {...rest}
        type={
          rest.type
            ? rest.type === "password"
              ? isPasswordVisible
                ? "text"
                : "password"
              : rest.type
            : "text"
        }
      />

      {rest.type === "password" && (
        <Image
          className={styles["btn-toggle-password-visibility"]}
          src={`/assets/icons/${isPasswordVisible ? "eye" : "eye-off"}.svg`}
          width={20}
          height={20}
          alt={`${isPasswordVisible ? "Hide" : "Show"} password`}
          onClick={togglePasswordVisibility}
        />
      )}

      {hasError && <p className={styles["text-error"]}>{error}</p>}
    </div>
  );
}
